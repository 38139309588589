<template>
    <div>
        需求方信息
    </div>
</template>

<script>
export default {
    name: 'HiringPlatform1PersonInfo',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="less" scoped></style>